import ListLayout from "../../components/layout/main/ListLayout";
import {PageCard} from "../../styles/CommonStyles";
import {useState} from "react";
import Tabbar from "../../components/common/tabs/Tabbar";
import FullnodeStats from "../../components/main/fullnode/FullnodeStats";
import FullnodeList from "../../components/main/fullnode/FullnodeList";
import FullnodeActions from "../../components/main/fullnode/FullnodeActions";


const Fullnode = () => {

    const [activeTab, setActiveTab] = useState(1)
    const comps = [
        FullnodeStats, FullnodeList, FullnodeActions
    ]
    const ActiveComp = comps[activeTab-1]

    return (
        <ListLayout>
            <PageCard>
                <Tabbar
                    tabs={['fullnode-stats', 'fullnode-list', 'fullnode-actions']}
                    active={activeTab}
                    onTabClicked={(idx) => setActiveTab(idx)}
                />
                <div style={{ marginTop: '20px' }}>
                    <ActiveComp />
                </div>
            </PageCard>
        </ListLayout>
    )
}

export default Fullnode
