import {CMargin, DText, Flex, Row} from "../../../../styles/CommonStyles";
import {SOCKET_URL} from "../../../../core/constants/urls";
import Text from "../../../../core/utils/Text";
import {formatNumber} from "../../../../core/utils/common";
import {MiniLineBreak, RespIndex} from "../../../../styles/main/MainCommonStyles";
import RTableLayout from "../../layout/RTableLayout";


const RFullnodeStatsTable = ({ data }) => {

    const { data: stats } = data

    return (
        <RTableLayout
            data={data}
            hasPagination={false}
        >
            {stats?.data?.map((stat, idx) => (
                <Row idx={idx} cs={'100%'} key={stat.symbol}>
                    <Flex justify='space-between'>
                        <Flex>
                            <img
                                src={
                                    stat.symbol === 'irt' ?
                                        require('../../../../assets/images/tooman.png')
                                        :
                                        SOCKET_URL + `assets/icon/${stat.symbol}.png`
                                }
                                width='32px'
                                alt=' '
                            />
                            <DText style={{ margin: '0 8px' }}>
                                {stat.symbol?.toUpperCase()}
                            </DText>
                        </Flex>
                    </Flex>
                    <CMargin margin='10px' />
                    <Flex justify='space-between'>
                        <DText main fontSize='s'>
                            <Text tid='amount' /> :
                        </DText>
                        <DText primary fontSize='s' style={{ lineBreak: 'anywhere', width: '70%', direction: 'ltr' }}>
                            {formatNumber(stat.amount)}
                        </DText>
                    </Flex>
                    <MiniLineBreak />
                    <Flex justify='space-between'>
                        <DText main fontSize='s'>
                            <Text tid='count' /> :
                        </DText>
                        <DText primary fontSize='s'>
                            {formatNumber(stat.count)}
                        </DText>
                    </Flex>
                    <RespIndex>
                        {idx + 1}
                    </RespIndex>
                </Row>
            ))}


        </RTableLayout>
    )
}

export default RFullnodeStatsTable
