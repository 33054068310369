import {useGetFullnodeStats} from "../../../core/services/react-query/fullnode";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../core/constants/common";
import FullnodeStatsTable from "./FullnodeStatsTable";
import RFullnodeStatsTable from "../../responsive/main/fullnode/RFullnodeStatsTable";


const FullnodeStats = () => {

    const { width } = useWindowSize()
    const { data: stats, isLoading } = useGetFullnodeStats()
    const Comp = width > TABLET_SIZE ? FullnodeStatsTable : RFullnodeStatsTable

    return (
        <Comp data={{ data:stats, loading: isLoading }} />
    )
}

export default FullnodeStats
