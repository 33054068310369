import MotionFade from "../../../../common/utils/MotionFade";
import useBlockAccess from "../../../../../core/hooks/main/setting/general/useBlockAccess";
import ABActionButton from "../ABActionButton";
import {Absolute, CMargin, Decoration, DText, Flex} from "../../../../../styles/CommonStyles";
import SettingRow from "../../../../common/common/SettingRow";
import Toggle from "../../../../common/buttons/Toggle";
import Text from "../../../../../core/utils/Text";
import {useWindowSize} from "../../../../../core/hooks/common/useWindowSize";
import {MOBILE_SIZE} from "../../../../../core/constants/common";


const BlockAccess = () => {

    const { width } = useWindowSize()
    const {
        accessData,
        onInputValueChange,
        loading,
        detailsChanged,
        onSubmitClicked
    } = useBlockAccess()

    return (
        <MotionFade>
            <CMargin margin={'30px'} />
            <Flex fw justify={'flex-start'}>
                <Decoration />
                <DText main>
                    <Text tid={'block-access-note'} />
                </DText>
            </Flex>
            <CMargin margin={'20px'} />
            <Flex fw wrap justify={'space-around'} >
                <Flex style={{ minWidth: '300px' }}>
                    <SettingRow
                        prefix={'deposit'}
                        suffix={
                            <Toggle
                                active={accessData?.deposit}
                                onChange={() => onInputValueChange(!accessData?.deposit, 'deposit')}
                            />
                        }
                    />
                </Flex>
                <Flex style={{ minWidth: '300px' }}>
                    <SettingRow
                        prefix={'withdraw'}
                        suffix={
                            <Toggle
                                active={accessData?.withdraw}
                                onChange={() => onInputValueChange(!accessData?.withdraw, 'withdraw')}
                            />
                        }
                    />
                </Flex>
                <Flex style={{ minWidth: '300px' }}>
                    <SettingRow
                        prefix={'order'}
                        suffix={
                            <Toggle
                                active={accessData?.order}
                                onChange={() => onInputValueChange(!accessData?.order, 'order')}
                            />
                        }
                    />
                </Flex>
                <Flex style={{ minWidth: '300px' }}>
                    <SettingRow
                        prefix={'otc'}
                        suffix={
                            <Toggle
                                active={accessData?.otc}
                                onChange={() => onInputValueChange(!accessData?.otc, 'otc')}
                            />
                        }
                    />
                </Flex>
            </Flex>
            <CMargin margin={'15px'} />
            {width > MOBILE_SIZE ?
                <ABActionButton
                    show={detailsChanged}
                    onClick={onSubmitClicked}
                    loading={loading}
                />
                :
                <Absolute style={{ position: 'fixed' }} left={0} bottom={0} height={'60px'}>
                    <ABActionButton
                        show={detailsChanged}
                        onClick={onSubmitClicked}
                        loading={loading}
                    />
                </Absolute>
            }

        </MotionFade>
    )
}


export default BlockAccess
