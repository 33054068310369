import {
    useChangeUserKycStatus,
    useRequestBirthKyc, useRequestFinnotechBirthKyc, useRequestFinnotechMobileKyc,
    useRequestUserMobileKyc
} from "../../../../../core/services/react-query/user";
import {useEffect} from "react";
import {Background, CFlex, CMargin, Decoration, DText, Flex} from "../../../../../styles/CommonStyles";
import Text from "../../../../../core/utils/Text";
import {AcceptUserRow, InquiryBtn, UserDetailsBox} from "../../../../../styles/main/user";
import {BiCheck} from "react-icons/bi";
import {RiCloseLine} from "react-icons/ri";
import {LineBreak} from "../../../../../styles/main/MainCommonStyles";
import ActionButton from "../../../../common/buttons/ActionButton";
import ModalLayout from "../../../../layout/main/ModalLayout";
import BasicModal from "../../../../modals/BasicModal";
import {useState} from "react";
import { ReactComponent as UserAccepted } from "../../../../../assets/illustrations/setting/user-accepted.svg";
import Skeleton from "../../../../../core/packages/skeleton";
import UserDetailSettingSkeleton from "../../../../skeleton/UserDetailSettingSkeleton";
import Loader from "../../../../common/loading/Loader";
import {useQueryContext} from "../../../../../core/contexts/query";



const AcceptUser = ({
    user,
    refetch
}) => {

    const {setToast} = useQueryContext()

    const {
        data: mobileKyc,
        isLoading: mobileKycLoading,
        mutate: requestMobileKyc,
        isSuccess: mobileKycSuccess
    } = useRequestUserMobileKyc()

    const {
        data: birthKyc,
        isLoading: birthKycLoading,
        mutate: requestBirthKyc,
        isSuccess: birthKycSuccess
    } = useRequestBirthKyc()

    const {
        data: fMobileKyc,
        isLoading: fMobileKycLoading,
        mutate: fRequestMobileKyc,
        isSuccess: fMobileKycSuccess
    } = useRequestFinnotechMobileKyc()

    const {
        data: fBirthKyc,
        isLoading: fBirthKycLoading,
        mutate: fRequestBirthKyc,
        isSuccess: fBirthKycSuccess
    } = useRequestFinnotechBirthKyc()

    const {
        mutate: changeState,
        isLoading: modalLoading,
        isSuccess: userStateChanged
    } = useChangeUserKycStatus()

    const [actionModal, setActionModal] = useState(false)
    const onActionModalClose = () => setActionModal(false)

    const onChangeUserState = () => {
        changeState({id: user?._id, data: {status: "VERIFIED"}})
    }

    const onAcceptUser = () => {
        setActionModal(true)
    }

    useEffect(() => {
        if (userStateChanged) {
            setActionModal(false)
            onActionModalClose()
            refetch()
        }
    }, [userStateChanged])


    const StatusIcon = ({match}) => {
        return (
            <>
                {match ?
                    <BiCheck color={'#37b88b'} size={22}/>
                    :
                    <RiCloseLine color={'#f6465d'} size={22}/>
                }
            </>
        )
    }

    const Status = ({birth, mobile, match}) => {

        if (birth || mobile) {
            return <StatusIcon match={match} />
        }
        else {
            return <DText main fontSize={'s'}>
                <Text tid={'not-inquired'} />
            </DText>
        }
    }

    const onBirthInquiry = () => {
        if (!birthKyc) {
            requestBirthKyc({
                idNo: user?.idNo,
                birthDate: user?.birthDate
            })
        }else {
            setToast({
                isError: true,
                show: true,
                message: 'already-inquired'
            })
        }
    }

    const onMobileInquiry = () => {
        if (!mobileKyc) {
            requestMobileKyc({
                idNo: user?.idNo,
                mobile: user?.mobile
            })
        }else {
            setToast({
                isError: true,
                show: true,
                message: 'already-inquired'
            })
        }
    }

    const onFBirthInquiry = () => {
        if (!fBirthKyc) {
            fRequestBirthKyc({
                idNo: user?.idNo,
                birthDate: user?.birthDate,
                firstName: user?.firstName,
                lastName: user?.lastName,
                fatherName: user?.fatherName,
                gender: user?.gender
            })
        }else {
            setToast({
                isError: true,
                show: true,
                message: 'already-inquired'
            })
        }
    }

    const onFMobileInquiry = () => {
        if (!fMobileKyc) {
            fRequestMobileKyc({
                idNo: user?.idNo,
                mobile: user?.mobile
            })
        }else {
            setToast({
                isError: true,
                show: true,
                message: 'already-inquired'
            })
        }
    }

    const mobileKycData = mobileKyc?.data?.data
    const birthKycData = birthKyc?.data?.data

    const fMobileKycData = fMobileKyc?.data?.data
    const fBirthKycData = fBirthKyc?.data?.data?.result

    return (
        <CFlex fw>
            <CMargin margin={'10px'} />
            { !user ?
                <Skeleton
                    Content={UserDetailSettingSkeleton}
                />
                :
                (user?.verifyAt ?
                        <>
                            <UserAccepted width={'280px'} height={'300px'}/>
                            <DText primary>
                                <Text tid={'user-already-accepted'} />
                            </DText>
                        </>
                        :
                        <>
                            <Flex fw>
                                <Decoration />
                                <DText main>
                                    <Text tid={'accept-user-note'} />
                                </DText>
                            </Flex>

                            <CMargin margin={'10px'} />
                            <Flex fw wrap align={'stretch'}>

                                {/* user details - self statement*/}
                                <UserDetailsBox>
                                    <Flex fw justify={'space-between'}>
                                        <Background bg={'tInputBg'} style={{ padding: '10px', borderRadius: '4px' }}>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'self-statement'} />
                                            </DText>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'status'} />
                                            </DText>
                                        </Background>
                                    </Flex>
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'firstName'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {user?.firstName}
                                            </DText>
                                        </Flex>
                                        <StatusIcon
                                            match={!!user?.firstName}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'lastName'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {user?.lastName}
                                            </DText>
                                        </Flex>
                                        <StatusIcon
                                            match={!!user?.lastName}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'fatherName'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {user?.fatherName}
                                            </DText>
                                        </Flex>
                                        <StatusIcon
                                            match={!!user?.fatherName}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'idNo'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {user?.idNo}
                                            </DText>
                                        </Flex>
                                        <StatusIcon
                                            match={!!user?.idNo}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'birthDate'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {user?.birthDate}
                                            </DText>
                                        </Flex>
                                        <StatusIcon
                                            match={!!user?.birthDate}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'mobile'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {user?.mobile}
                                            </DText>
                                        </Flex>
                                        <StatusIcon
                                            match={!!user.mobile}
                                        />
                                    </AcceptUserRow>
                                    <CMargin margin={'10px'} />
                                </UserDetailsBox>

                                {/* inquiry data faraboom */}
                                <UserDetailsBox>
                                    <Flex fw justify={'space-between'}>
                                        <Background bg={'tInputBg'} style={{ padding: '10px', borderRadius: '4px' }}>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'user-inquiry-faraboom'} />
                                            </DText>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'status'} />
                                            </DText>
                                        </Background>
                                    </Flex>
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'firstName'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {birthKycData?.first_name}
                                            </DText>
                                        </Flex>
                                        <Status
                                            match={birthKycData?.first_name === user?.firstName}
                                            birth={birthKycSuccess}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'lastName'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {birthKycData?.last_name}
                                            </DText>
                                        </Flex>
                                        <Status
                                            match={birthKycData?.last_name === user?.lastName}
                                            birth={birthKycSuccess}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'fatherName'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {birthKycData?.father_name}
                                            </DText>
                                        </Flex>
                                        <Status
                                            match={birthKycData?.father_name === user?.fatherName}
                                            birth={birthKycSuccess}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'idNo'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {birthKycData?.national_code}
                                            </DText>
                                        </Flex>
                                        <Status
                                            match={birthKycData?.national_code === user?.idNo}
                                            birth={birthKycSuccess}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'birthDate'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {birthKycData?.match}
                                            </DText>
                                        </Flex>
                                        <Status
                                            match={birthKycData?.match}
                                            birth={birthKycSuccess}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'mobile'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {mobileKycData?.match}
                                            </DText>
                                        </Flex>
                                        <Status
                                            match={mobileKycData?.match}
                                            birth={mobileKycSuccess}
                                        />
                                    </AcceptUserRow>
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <InquiryBtn onClick={onBirthInquiry} active={!birthKyc}>
                                            {birthKycLoading ?
                                                <Loader />
                                                :
                                                <Text tid={'birth-inquiry-faraboom'} />
                                            }
                                        </InquiryBtn>
                                        <InquiryBtn onClick={onMobileInquiry} active={!mobileKyc}>
                                            {mobileKycLoading ?
                                                <Loader />
                                                :
                                                <Text tid={'mobile-inquiry-faraboom'} />
                                            }
                                        </InquiryBtn>
                                    </AcceptUserRow>
                                    <CMargin margin={'10px'} />

                                </UserDetailsBox>

                                {/* inquiry data finnotech */}
                                <UserDetailsBox>
                                    <Flex fw justify={'space-between'}>
                                        <Background bg={'tInputBg'} style={{ padding: '10px', borderRadius: '4px' }}>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'user-inquiry-finno'} />
                                            </DText>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'status'} />
                                            </DText>
                                        </Background>
                                    </Flex>
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'firstName'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {fBirthKycData?.firstName}
                                            </DText>
                                        </Flex>
                                        <Status
                                            match={fBirthKycData?.firstNameSimilarity >= 100}
                                            birth={fBirthKycSuccess}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'lastName'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {fBirthKycData?.lastName}
                                            </DText>
                                        </Flex>
                                        <Status
                                            match={fBirthKycData?.lastNameSimilarity >= 100}
                                            birth={fBirthKycSuccess}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'fatherName'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {fBirthKycData?.fatherName}
                                            </DText>
                                        </Flex>
                                        <Status
                                            match={fBirthKycData?.fatherNameSimilarity >= 100}
                                            birth={fBirthKycSuccess}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'idNo'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {fBirthKycData?.nationalCode}
                                            </DText>
                                        </Flex>
                                        <Status
                                            match={fBirthKycData?.nationalCode === user?.idNo}
                                            birth={fBirthKycSuccess}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'birthDate'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {fBirthKycData?.birthDate}
                                            </DText>
                                        </Flex>
                                        <Status
                                            match={fBirthKycData?.birthDate?.split('/')?.join('-') === user?.birthDate}
                                            birth={fBirthKycSuccess}
                                        />
                                    </AcceptUserRow>
                                    <LineBreak />
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <Flex>
                                            <DText main fontSize={'s'}>
                                                <Text tid={'mobile'} /> :
                                            </DText>
                                            <DText main style={{ margin: '0 8px' }}>
                                                {fMobileKycData?.result?.isValid}
                                            </DText>
                                        </Flex>
                                        <Status
                                            match={fMobileKycData?.result?.isValid}
                                            birth={fMobileKycSuccess}
                                        />
                                    </AcceptUserRow>
                                    <CMargin margin={'10px'} />

                                    <AcceptUserRow>
                                        <InquiryBtn onClick={onFBirthInquiry} active={!fBirthKyc}>
                                            {fBirthKycLoading ?
                                                <Loader />
                                                :
                                                <Text tid={'birth-inquiry-finno'} />
                                            }
                                        </InquiryBtn>
                                        <InquiryBtn onClick={onFMobileInquiry} active={!fMobileKyc}>
                                            {fMobileKycLoading ?
                                                <Loader />
                                                :
                                                <Text tid={'mobile-inquiry-finno'} />
                                            }
                                        </InquiryBtn>
                                    </AcceptUserRow>
                                    <CMargin margin={'10px'} />

                                </UserDetailsBox>
                            </Flex>
                            <CMargin margin={'15px'} />
                            <Flex fw>
                                <Flex width={'300px'}>
                                    <ActionButton active onClick={onAcceptUser} height={'42px'}>
                                        <Text tid={'submit'} />
                                    </ActionButton>
                                </Flex>
                            </Flex>

                            <ModalLayout
                                open={actionModal}
                                width={'520px'}
                                onClose={onActionModalClose}
                            >
                                <BasicModal
                                    head={`accept-user-head`}
                                    _id={user?._id}
                                    onClose={onActionModalClose}
                                    onSubmit={onChangeUserState}
                                    loading={modalLoading}
                                />
                            </ModalLayout>
                        </>
                )
            }
        </CFlex>
    )
}


export default AcceptUser
