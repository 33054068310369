import TableLayout from "../../layout/main/TableLayout";
import {Column, DText, Flex, Relative, Row} from "../../../styles/CommonStyles";
import useGetTableIndex from "../../../core/hooks/layout/useGetTableIndex";
import Avatar from "../../common/utils/Avatar";
import {TradeTypeBadge} from "../../../styles/main/orders";
import {getRandomColor, getTradeTypeColor} from "../../../core/utils/theme";
import Text from "../../../core/utils/Text";
import {formatCardNumber, formatName, getNames} from "../../../core/utils/common";
import Operation from "../../common/utils/Operation";
import {bankAccountOperations} from "../../../core/constants/operation";
import {useMainContext} from "../../../core/contexts/main";
import UserBankActions from "../users/UserBankActions";
import useBankAccounts from "../../../core/hooks/main/bank-accounts/useBankAccounts";
import UserLink from "../../common/utils/UserLink";
import {bankAccountAttributes} from "../../../core/constants/headers";
import {useMemo} from "react";

const BankAccountsTable = ({ data }) => {

    const { lang } = useMainContext()
    const { cs, headers } = bankAccountAttributes
    const { getTableIndex } = useGetTableIndex()
    const { data: banks } = data

    const {
        hasWriteAccess,
        actionMode,
        closeActionMode,
        onOptionClicked
    } = useBankAccounts()

    const banksOnBoard = useMemo(() => {
        let temp = []
        banks?.data?.forEach(bank => {
            bank.banks?.forEach(item => {
                temp.push({...item, bank})
            })
        })
        return temp
    }, [banks])


    return (
        <>
            {actionMode.show ?
                <UserBankActions
                    mode={actionMode}
                    closeActionMode={closeActionMode}
                    kycMode
                />
                :
                <TableLayout
                    headers={headers}
                    cs={cs}
                    data={data}
                    hasWriteAccess={hasWriteAccess}
                >
                    {banksOnBoard.map((item, idx) => {

                        const { FULLNAME } = getNames(item.bank.firstName, item.bank.lastName)
                            return (
                                <Relative>
                                    <Row index={idx} cs={cs}>
                                        <Column>
                                            {getTableIndex(idx)}
                                        </Column>
                                        <Column>
                                            <Flex>
                                                <Avatar
                                                    avatar={item.bank.avatar}
                                                    name={item.bank.firstName}
                                                />
                                                <UserLink
                                                    _id={item.bank._id}
                                                    name={formatName(FULLNAME)}
                                                />
                                            </Flex>
                                        </Column>
                                        <Column>
                                            <TradeTypeBadge
                                                color={getTradeTypeColor(item.bank.verifyAt ? 'success' : 'error')}>
                                                <Text tid={item.bank.verifyAt ? 'verified' : 'not-verified'}/>
                                            </TradeTypeBadge>
                                        </Column>
                                        <Column style={{letterSpacing: '1.2px'}}>
                                            {formatCardNumber(item.cardNo)}
                                        </Column>
                                        <Column style={{letterSpacing: '1.1px'}}>
                                            {formatName(item.shebaNo, 6, 2)}
                                        </Column>
                                        <Column>
                                            <TradeTypeBadge color={getRandomColor(idx % 3)}>
                                                {item.label?.substring(0, 10)}
                                            </TradeTypeBadge>
                                        </Column>
                                        <Column>
                                            {item.verifyBy ?
                                                <UserLink
                                                    _id={item.verifyBy}
                                                    name={formatName(item.verifyBy, 6, 2)}
                                                />
                                                :
                                                <TradeTypeBadge
                                                    color={getTradeTypeColor(item.verifyAt ? 'success' : 'error')}>
                                                    <Text tid={item.verifyAt ? 'verified' : 'not-verified'}/>
                                                </TradeTypeBadge>
                                            }

                                        </Column>
                                    </Row>
                                    <Column operation style={{top: '40%', left: lang === 'fa' && '20px'}}>
                                        <Operation
                                            options={bankAccountOperations}
                                            onOptionClicked={(idx) => onOptionClicked(idx, item, item.bank)}
                                            hasWriteAccess={hasWriteAccess}
                                        />
                                    </Column>
                                </Relative>
                            )})
                    }
                </TableLayout>
            }
        </>
    )
}


export default BankAccountsTable
