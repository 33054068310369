import FilterLayout from "../../../components/layout/filter/FilterLayout";
import ListLayout from "../../../components/layout/main/ListLayout";
import {PageCard} from "../../../styles/CommonStyles";
import { useGetBankTransactions } from '../../../core/services/react-query/report/banking'
import {
    CacheKeys,
    bankTransactionOptions,
    bankTransactionSortOptions
} from "../../../core/constants/filter";
import BankTransactionTable from "../../../components/main/reports/bank-transactions/BankTransactionTable";
import { TABLET_SIZE } from "../../../core/constants/common";
import { useWindowSize } from "../../../core/hooks/common/useWindowSize";
import { useState } from "react";
import RBankTransactionTable from "../../../components/responsive/main/reports/bank-transactions/RBankTransactionTable";
import {useAclContext} from "../../../core/contexts/acl";


const BankTransaction = () => {

    const { width } = useWindowSize()
    const [transactions, setTransactions] =  useState({data: null, loading: true})

    const {permissions} = useAclContext()
    const hasDownload = permissions?.export?.write

    const onQuerySuccess = (res) => {
        setTransactions(res)
    }

    const Component = width > TABLET_SIZE ? BankTransactionTable : RBankTransactionTable

    return (
        <ListLayout>
            <PageCard>
                <FilterLayout
                    query={useGetBankTransactions}
                    onQuerySuccess={onQuerySuccess}
                    options={bankTransactionOptions}
                    cache={CacheKeys.BANK_TRANSACTIONS}
                    hasDownload={hasDownload}
                    sortOptions={bankTransactionSortOptions}
                >
                    {width &&
                        <Component
                            data={transactions}
                        />
                    }

                </FilterLayout>
            </PageCard>
        </ListLayout>
    )
}

export default BankTransaction;
