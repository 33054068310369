import FilterLayout from "../../layout/filter/FilterLayout";
import {useGetFullnodeList} from "../../../core/services/react-query/fullnode";
import {useState} from "react";
import {CacheKeys, fullnodeFilterOptions, fullnodeSortOptions} from "../../../core/constants/filter";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import {TABLET_SIZE} from "../../../core/constants/common";
import FullnodeListTable from "./FullnodeListTable";
import RFullnodeListTable from "../../responsive/main/fullnode/RFullnodeListTable";


const FullnodeList = () => {

    const { width } = useWindowSize()
    const [data, setData] = useState({loading: true, data: null})
    const onQuerySuccess = (res) => setData(res)
    const Comp = width > TABLET_SIZE ? FullnodeListTable : RFullnodeListTable

    return (
        <FilterLayout
            query={useGetFullnodeList}
            cache={CacheKeys.FULLNODE}
            options={fullnodeFilterOptions}
            onQuerySuccess={onQuerySuccess}
            sortOptions={fullnodeSortOptions}
        >
            <Comp data={data} />
        </FilterLayout>
    )
}

export default FullnodeList
