import {useParams} from "react-router-dom";
import OrdersDetail from "../reports/orders/OrdersDetail";
import CoinTransactionDetails from "../reports/coin-transactions/CoinTransactionDetails";
import BankTransactionDetails from "../reports/bank-transactions/BankTransactionDetails";
import PageNotFound from "../../components/layout/main/PageNotFound";


const UserSubDetails = () => {

    const params = useParams()

    const components = {
        'trades': OrdersDetail,
        'current-orders': OrdersDetail,
        'otc-orders': OrdersDetail,
        'coin-transactions': CoinTransactionDetails,
        'bank-transactions': BankTransactionDetails
    }

    const Comp = components[params.tab]

    if (!Comp) {
        return <PageNotFound />
    }

    return (
        <Comp />
    )
}

export default UserSubDetails
