import {Background, CFlex, CMargin, DText, Padding} from "../../styles/CommonStyles";
import {AlertIcon, ModalHeader} from "../../styles/modals";
import Text from "../../core/utils/Text";
import {COINING_KEYS, useGetCoiningConfig, useUpdateCoinTransaction} from "../../core/services/react-query/report/coining";
import Select from "../common/dropdown/Select";
import {useState} from "react";
import Input from "../common/input/Input";
import ActionButton from "../common/buttons/ActionButton";
import {useQueryContext} from "../../core/contexts/query";
import {BANKING_KEYS, useGetBankingConfig, useUpdateBankTransaction} from "../../core/services/react-query/report/banking";


const TransactionModal = ({
    details,
    onClose
}) => {

    const { queryClient, setToast } = useQueryContext()

    const onTransactionUpdate = () => {
        onClose()
        queryClient.invalidateQueries(
            details.type === 'coining' ?
                COINING_KEYS.GET_A_TRANSACTION
                :
                BANKING_KEYS.GET_A_TRANSACTION
            )
    }

    const configQuery = details.type === 'coining' ? useGetCoiningConfig : useGetBankingConfig
    const { data: config } = configQuery()

    const query = details.type === 'coining' ? useUpdateCoinTransaction : useUpdateBankTransaction
    const { mutate: updateTransaction, isLoading } = query(onTransactionUpdate)

    const [thirdParty, setThirdParty] = useState('')
    const [note, setNote] = useState('')

    const valid = (note || (details?.action === 'approve' && thirdParty))

    if (!details.transaction) {
        return <></>
    }

    const STATUS = details?.action === 'approve' ? 'success' : (
        details?.action === 'reject' ? 'error' : 'rollback'
    )

    const onSubmitClicked = () => {
        if (valid) {
            let payload = {
                id: details?.transaction?._id,
                data: {
                    status: STATUS,
                    note,
                    thirdParty
                }
            }
            updateTransaction(payload)
        }else {
            setToast({
                isError: true, show: true,
                message: 'fill-inputs-error'
            })
        }
    }

    return (
        <CFlex fw>
            <ModalHeader>
                <DText primary>
                    <Text tid={`${details.action}-${details.type}`} />
                </DText>
                <AlertIcon size={30}/>
            </ModalHeader>
            <CMargin margin={'15px'} />

            <CFlex fw align={'flex-start'}>
                {thirdParty &&
                    <DText main>
                        <Text tid={'select-thirdparty'} />
                    </DText>
                }
                {details?.action === 'approve' &&
                <>
                <CMargin margin={'4px'} />
                    <Padding padding={'4px'}>
                        <Select
                            width={'100%'}
                            options={config ? config.thirdPartyWithdraw : []}
                            value={thirdParty}
                            placeHolder={'select-thirdparty'}
                            onValueChange={(idx) => setThirdParty(config.thirdPartyWithdraw[idx])}
                        />
                    </Padding>
                </>

                }

                <CMargin margin={'8px'} />
                {details?.action !== 'approve' &&
                    <Background bg={'mainBg'}>
                        <Input
                            value={note}
                            onInputChange={(v) => setNote(v)}
                            label={`${details?.action}-reason`}
                        />
                    </Background>
                }
                <CMargin margin={'6px'} />
                <ActionButton
                    onClick={onSubmitClicked}
                    active={valid}
                    loading={isLoading}
                >
                    <Text tid={'submit'} />
                </ActionButton>
            </CFlex>
        </CFlex>
    )
}

export default TransactionModal
