import {Column, DText, Flex, Row} from "../../../styles/CommonStyles";
import {SOCKET_URL} from "../../../core/constants/urls";
import {formatNumber} from "../../../core/utils/common";
import TableLayout from "../../layout/main/TableLayout";
import {fullnodeStatAttributes} from "../../../core/constants/headers";


const FullnodeStatsTable = ({ data }) => {

    const { data: stats } = data
    const { cs, headers } = fullnodeStatAttributes

    return (
        <TableLayout
            headers={headers}
            cs={cs}
            data={data}
            hasPagination={false}
            hasWriteAccess
        >
            {stats?.data?.map((stat, idx) => (

                <Row cs={cs} index={idx} key={stat.symbol}>
                    <Column>
                        {idx + 1}
                    </Column>
                    <Column>
                        <Flex>
                            <img
                                src={
                                    stat.symbol === 'irt' ?
                                        require('../../../assets/images/tooman.png')
                                        :
                                        SOCKET_URL + `assets/icon/${stat.symbol}.png`
                                }
                                width='32px'
                                alt=' '
                            />
                            <DText style={{ margin: '0 8px' }}>
                                {stat.symbol?.toUpperCase()}
                            </DText>
                        </Flex>
                    </Column>
                    <Column style={{ lineBreak: 'anywhere' }}>
                        {formatNumber(stat.amount)}
                    </Column>
                    <Column>
                        {formatNumber(stat.count)}
                    </Column>
                </Row>
            ))}
        </TableLayout>
    )
}

export default FullnodeStatsTable
